import ArticlePreview from '../components/article-preview'
import Button from '../components/button'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import React from 'react'
import blogPost from './blog-post.module.css'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import reviewStyles from './reviews.module.css'

class RootIndex extends React.Component {
  render() {
    const exhibitions = get(this, 'props.data.allContentfulExhibition.edges')
    const reviews = get(this, 'props.data.allContentfulQuote.edges')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    console.log(reviews)

    return (
      <Layout location={this.props.location} exhibitions={exhibitions}>
        <Helmet title={`Reviews | ${siteTitle}`} />
        <div className={blogPost.wrapper}>
          <div className={blogPost.leftColumn}>
            <h1 className={blogPost.title}>Reviews</h1>
            <p>
              Here are some reviews from previous exhibitions. Other people's
              opinions of my work are always welcome. So I look forward to
              hearing from you on the current exhibitions on this site - Tommy.
            </p>
            <Button to="/contact">Contact</Button>
          </div>
          <div className={blogPost.rightColumn}>
            <ul className={reviewStyles.reviewList}>
              {reviews.map(({ node }) => (
                <li className={reviewStyles.reviewContainer}>
                  <p>
                    <i className={reviewStyles.quotationMark}>&#10077;</i>
                    {node.quote.quote}
                  </p>
                  <h3 className={reviewStyles.reviewName}>- {node.name}</h3>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query ReviewPage {
    site {
      siteMetadata {
        title
      }
    }
    
    allContentfulExhibition {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulQuote {
      edges {
        node {
          id
          name
          quote {
            quote
          }
        }
      }
    }
  }
`
